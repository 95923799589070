import GlobalEmitter from '~/glxp/utils/emitter'
import { BREAKPOINTS } from '/utils/constants'

const isMobile = ref(false)
function checkMobile() {
  isMobile.value = window.innerWidth < BREAKPOINTS.L
}

export function useIsMobile() {
  onMounted(() => {
    checkMobile()
    GlobalEmitter.on('debouncedResize', checkMobile)
  })
  onBeforeUnmount(() => {
    GlobalEmitter.off('debouncedResize', checkMobile)
  })

  return isMobile
}
